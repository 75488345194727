import React, { useEffect, createRef, useState } from "react"
//Components
import Layout from "../components/layout"
// bootstrap ----------------------
import { Col, Container, Row, Button } from 'react-bootstrap';
// css ---------------------------
import { css } from '@emotion/react';
import styled from '@emotion/styled';
// images -------------------------
import imgCity from './../../static/city.svg'
import imgBannerRadial from './../../static/backgroundBanner.png'
// animation
import lottie from "lottie-web";
import animation from "../resources/animations/31760-infographics-dashboard.json";
import animation2 from "../resources/animations/19507-pc-to-phone.json";
// links
import { Link } from 'gatsby';
// video
import Video from './../components/video/Video';

const IndexPage = () => {

	const [showVideo, setShowVideo] = useState(false)

	const animationContainer = createRef()
	const animationContainer2 = createRef()

	useEffect(() => {
		const anim = lottie.loadAnimation({
			container: animationContainer.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: animation
		});
		return () => anim.destroy(); // optional clean up for unmounting
	}, []);

	useEffect(() => {
		const anim2 = lottie.loadAnimation({
			container: animationContainer2.current,
			renderer: "svg",
			loop: true,
			autoplay: true,
			animationData: animation2
		});
		return () => anim2.destroy(); // optional clean up for unmounting
	}, []);

	const Radial = styled.section`
		min-height:100vh;
		background-image: url(${imgBannerRadial}); /* The image used */
		background-position: center; /* Center the image */
		background-repeat: no-repeat; /* Do not repeat the image */
		background-size: cover; /* Resize the background image to cover the entire container */
		position:relative;
	`;
	const Main = styled.div`
		min-height:90vh;
		background-image: url(${imgCity}); /* The image used */
		background-position: bottom; /* Center the image */
		background-repeat: no-repeat; /* Do not repeat the image */
		background-size: contain; /* Resize the background image to cover the entire container */
		position:relative;
		position: absolute;
		/* top:20%; */
		left:0;
		right:0;
		top:0;
	`;

	const Title = styled.h1`
		font-size:3.5rem;
		color:#293594;
		font-weight:bold;
		padding-top:20%;
		@media (max-width: 1400px){
			padding-top:30%;
		}
		@media (max-width: 992px){
			padding-top:40%;
		}
		@media (max-width: 768px){
			padding-top:70%;
			text-align:center !important;
		}
	`;
	const Management = styled.section`
		margin-top:2rem;
		.container .row{
			@media (max-width: 992px){
				justify-content:center !important;
			}
		}
	`;
	const TitleManagement = styled.h2`
		font-size:2.5rem;
		color:#293594;
		font-weight:bold;
		/* @media (max-width: 768px){
			text-align:center !important;
		} */
	`;
	const Gallery = styled.div`
		margin: 1rem auto;
		width:100%;
		padding:5px;
		box-sizing: border-box;
		column-count: 2;
		/* Espacio entre columnas */
		-moz-column-gap: 5px;
		-webkit-column-gap: 5px;
		column-gap: 5px;
		max-height: 870px;
		@media(max-width:1200px){
			max-height: 1120px;
		}
		@media(max-width:1080px){
			display:none !important;
		}
		img{
			background:#fff;
            margin: 5px;
            text-align: center;
            max-width: 100%;
            /*Evitamos que se corte al cambiar de columna*/
            break-inside: avoid;
            page-break-inside: avoid;
			width:100%;
			height:auto;
		}
	`;
	const ImgCharts = styled.img`
		top: 10%;
		bottom: 0;
		right: 28%;
		z-index: 1;
		position:absolute;
		@media(max-width:1200px){
			position: initial;
			width: auto !important;
		}
		@media(max-width:1620px){
			width: 30vw;
		}
	`;
	const AccessDevice = styled.section`
		padding-top:2%;
		margin-top:5%;
		@media (max-width:1200px){
			padding-top:0%;
			margin-top:0%;
		}
	`;
	const TitleAccessDevice = styled.h2`
		font-size:3rem;
		color:#293594;
		font-weight:bold;
		@media (max-width: 1200px){
			margin-top:3rem;
		}
	`;
	const AnimationAccessDevice = styled.div`
		/* background-color:#E1F095; */
		.containerAnimation{
			display:none !important;
			@media(min-width:1200px){
				display:block !important;
			}
		}
	`;
	const StickyNotes = styled.section`
		padding-top:10rem;
		margin-bottom:-15rem !important;
		@media(max-width:1200px){
			padding-top:2rem;
		}
	`;
	const NotesPaper = styled.div`
	
		padding:20px;
		box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
		width:380px;
		height :381px;
		margin-bottom:2rem;
		position:resize;
		p.bodyTextCard{
			font-family: 'Gloria Hallelujah', cursive;
			font-size:20px;
			line-height:39.65px;
		}
		p.titleTextCard, p.pieTextCard{
			font-family: 'Roboto', sans-serif;
			font-weight:bold;
			font-size:20px;
			/* line-height: 23.44; */
		}
		@media(max-width:1200px){
			width:100% !important;
			height:auto !important;
				min-height:400px;
		}
		@media(max-width:768px){
			min-height:auto !important;
		}

	`;
	const ContainerCardOne = styled(Col)`
		div{
			background-color:#F9F9F9;
		}
	`;
	const ContainerCardTwo = styled(Col)`
		div{
			background-color:#3AC0C7;
		}
	`;
	const ContainerCardThree = styled(Col)`
		div{
			background-color:#A9E7EA;
		}
	`;
	const SocialEnergy = styled.section`
		padding-top:20rem;
		padding-bottom:10rem;
		background: #E1F095;
		border-radius: 0px 0px 120px 120px;
		@media (max-width:992px){
			padding-top:30%;
			padding-bottom:5rem;
		}
		@media (max-width:768px){
			padding-top:40%;
			padding-bottom:3rem;
			border-radius: 0px 0px 50px 50px;
		}
		@media (max-width:550px){
			padding-top:55%;
		}
		@media (max-width:420px){
			padding-top:60%;
		}
		@media (max-width:470px){
			padding-top:80%;
		}
		/* margin-bottom:-7rem; */
	`;
	const IconsSocialEnergy = styled.div`
		width: 100px;
		height: 100px;
		background: #FFFFFF;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
		border-radius:50%;
		position: relative;
		img{
			position:absolute;
			top:0;
			bottom:0;
			left:0;
			right:0; 
			margin:auto;
		}
	`;
	const Crew = styled.section`
		background: linear-gradient(180deg, rgba(169, 231, 234, 0.58) 8.54%, rgba(196, 196, 196, 0) 100%);
		padding-top:5rem;
		@media (max-width:992px){
			padding-top:0rem;
		}


	`;
	const ImageCrew = styled.img`
		/* background: #FFFFFF; */
		box-shadow: 0px 20px 10px rgba(0, 0, 0, 0.15);
		border-radius:5px;
	`;
	const Innovation = styled.section`
		
	`;
	const ContainerTitleVideo = styled.div`
		width:70%;
		margin:auto;
		@media(max-width:992px){
			width:43rem;
		} 
		@media(max-width:768px){
			width:30rem;
			h2{
				font-size:2rem !important;
			}
		} 
		@media(max-width:768px){
			h2{
				font-size:1.5rem !important;
			}
		} 
		@media(max-width:576px){
			width:93%;
		} 
	`;



	return (
		<Layout>
			<Radial>
				<Main>
					<Container>
						<Title>Consumo inteligente <br /> al alcance de todos</Title>
					</Container>
				</Main>
			</Radial>
			<Management >
				<Container>
					<Row className="d-flex align-items-center justify-content-between position-relative">
						<Col lg={5} md={12} className="mb-5 mb-lg-5">
							<TitleManagement>Eficiencia energética para sistemas de ACS y calefacción para comunidades</TitleManagement>
							<p className="mt-3text-md-left" css={css`font-size:1.5rem;`}>
								Conoce los ahorros que puede obtener tu comunidad con nuestra
								propuesta de recambio de calderas de gas por bombas de
								calor. <span><Link css={css`color:#293594;`} to="/contacto">Contáctanos</Link></span> para realizar una evaluación
								gratuita para tu comunidad.
							</p>
							<Link to="/nosotros" className=" text-center text-md-left ">
								<Button variant="primary" className="border-radius-20 mt-4">CONOCER MAS</Button>
							</Link>
						</Col>
						<ImgCharts className="img-fluid d-none d-lg-block" src={'/graphics.png'} />
						<Col lg={12} xl={6} css={css`display:flex;`}>
							<Gallery className="d-none d-lg-block">
								<img alt="windtower" className="img-fluid" src={'/windtower.jpg'} />
								<img alt="electricalTechnician" className="img-fluid" src={'/electricalTechnician.jpg'} />
								<img alt="roof" className="img-fluid" src={'/roof.jpg'} />
								<img alt="houses" className="img-fluid" src={'/houses.jpg'} />
							</Gallery>
						</Col>
					</Row>
				</Container>
			</Management>
			<AccessDevice>
				<Container>
					<Row className="d-flex align-items-center justify-content-between my-0 my-lg-5">
						<Col xl={5}>
							<AnimationAccessDevice className="py-5">
								<div className="animation-container" ref={animationContainer2} />
							</AnimationAccessDevice>
						</Col>
						<Col xl={6}>
							<TitleAccessDevice>Accede desde <br />cualquier dispositivo</TitleAccessDevice>
							<p className="mt-3" css={css`font-size:1.5rem;`}>
								Cada cliente tiene el control y acceso a su propio consumo diario desde nuestra plataforma App.
							</p>
						</Col>
					</Row>
				</Container>
			</AccessDevice>
			<StickyNotes>
				<Container>
					<Row className="justify-content-center">
						<ContainerCardOne xl="auto" lg={4} md={6}>
							<NotesPaper>
								<div>
									<p className="my-4 bodyTextCard">
										"A lo largo de espacio hay energía, y es una mera cuestión
										de tiempo hasta que los hombres tengan éxito en sus
										mecanismos vinculados al aprovechamiento de esa energía."
									 </p>
								</div>
								<p className="text-right mb-0 pieTextCard position-absolute" css={css`bottom:3rem;right:2rem;`}>Nikola Tesla</p>
							</NotesPaper>
						</ContainerCardOne>
						<ContainerCardThree xl="auto" lg={4} md={6}>
							<NotesPaper>
								<div>
									<p className="my-4 bodyTextCard">
										"El cambio no se logrará si solo lo hacen algunos, el cambio ocurre si
										lo hacemos todos. Por ti, por tu familia y por el
										planeta, únete hoy al cambio para el mañana"
									</p>
								</div>
								<p className="text-right mb-0 pieTextCard position-absolute" css={css`bottom:3rem;right:2rem;`}>Equipo Social Energy</p>
							</NotesPaper>
						</ContainerCardThree>
						<ContainerCardTwo xl="auto" lg={4} md={12} className="d-none d-lg-block">
							<NotesPaper>
								<div>
									<p className="my-4 bodyTextCard">
										"La conjunción de la tecnología de la comunicación de Internet
										y las energías renovables está dando lugar a una
										Tercera Revolución Industrial."
									</p>
								</div>
								<p className="text-right mb-0 pieTextCard position-absolute" css={css`bottom:3rem;right:2rem;`}>Jeremy Rifkin</p>
							</NotesPaper>
						</ContainerCardTwo>
					</Row>
				</Container>
			</StickyNotes>
			<section css={css`background-color:#CEF2F3;`}>
				<SocialEnergy>
					<Container fluid className="mb-5">
						<Row className="d-flex align-items-center justify-content-between">
							<Col lg={6} className="pb-5">
								<ContainerTitleVideo>
									<TitleManagement>Te invitamos a conocer más sobre nuestra propuesta eficiencia energética para sistemas de ACS y calefacción para comunidades </TitleManagement>
								</ContainerTitleVideo>
							</Col>
							<Col lg={6} className="pr-lg-0">
								{
									showVideo ?
										<Video
											// videoSrcURL="https://www.youtube.com/embed/dQw4w9WgXcQ"
											videoSrcURL="https://www.youtube.com/embed/RnuU8h96Igs"
											videoTitle="Social Energy"
										/>
										:
										<div onClick={() => setShowVideo(true)} css={css`cursor:pointer;`}>
											<img alt="video" className="img-fluid w-100" src={'/video.jpg'} />
										</div>
								}

							</Col>
						</Row>
					</Container>
					<Container>
						<div css={css`
							margin-top:7rem;
							@media(max-width:992px){
								margin-top:0 !important;
							} 
						`}>
							<h2 className="text-center mb-4"><strong>Social Energy</strong> es consumo energético <br />en la palma de tu mano</h2>
							<p className="text-center">Nuestra propuesta conlleva diversas ventajas para la comunidad y el planeta</p>
						</div>
						<Row className="justify-content-between" css={css`padding-top:3rem;`}>
							<Col xl={3} lg={6} md={6} sm={12} className="d-flex align-items-center text-center mb-3">
								<IconsSocialEnergy>
									<img alt="leaf" className="img-fluid w-50" src={'/ahorroEnergetico.svg'} />
								</IconsSocialEnergy>
								<p className="ml-3">Ahorro monetario   </p>
							</Col>
							<Col xl={3} lg={6} md={6} sm={12} className="d-flex align-items-center text-center mb-3">
								<IconsSocialEnergy>
									<img alt="bulb" className="img-fluid w-50" src={'/reduccionCO2.svg'} />
								</IconsSocialEnergy>
								<p className="ml-3">reducción emisiones CO2  </p>
							</Col>
							<Col xl={3} lg={6} md={6} sm={12} className="d-flex align-items-center text-center mb-3">
								<IconsSocialEnergy>
									<img alt="sun" className="img-fluid w-50" src={'/eliminacionGastos.svg'} />
								</IconsSocialEnergy>
								<p className="ml-3">Eliminación de gastos en <br /> mantención y reparación  </p>
							</Col>
							<Col xl={3} lg={6} md={6} sm={12} className="d-flex align-items-center text-center mb-3">
								<IconsSocialEnergy>
									<img alt="house" className="img-fluid w-50" src={'/tarifaFija.svg'} />
								</IconsSocialEnergy>
								<p className="ml-3">tarifas fijas </p>
							</Col>
						</Row>
					</Container>
				</SocialEnergy>
			</section>
			<Crew>
				<Container>
					<Row className="d-flex align-items-center justify-content-between pt-5">
						<Col xl={6} lg={12}>

							<TitleManagement>Un equipo listo para <br />atenderte</TitleManagement>
							<p className="mt-3text-md-left" css={css`font-size:1.5rem;`}>
								Somos un equipo de Jóvenes motivados por la energía sustentable y
								listo para entregarte el mejor trabajo desde
								el profesionalismo y cercanía con el cliente
								</p>
							<div className="text-md-left">
								<Link to="/nosotros" className="border-radius-20 mt-4 btn btn-primary text-uppercase">Conócenos más</Link>
							</div>

						</Col>
						<Col xl={6} lg={12} className="">
							<Container>
								<Row className="d-flex align-items-center justify-content-between pt-5">
									<Col xl={6} className="mb-5 mb-sm-0 mb-lg-5 d-none d-xl-block">
										<Row>
											<Col xl={12} lg={6} sm={6}>
												<ImageCrew alt="Man" className="img-fluid mb-5 w-100" src={'/Fernando soto.png'} />
											</Col>
											<Col xl={12} lg={6} sm={6}>
												<ImageCrew alt="Woman" className="img-fluid  w-100" src={'/Tahia Villegas.png'} />
											</Col>
										</Row>
									</Col>
									<Col xl={6} lg={6} className=" d-none d-xl-block">
										<ImageCrew alt="man-two" className="img-fluid w-100 d-none d-xl-block" src={'/Sebastian Parada.png'} />
									</Col>
									<Col>
										<img alt="crew" className="img-fluid w-100 d-block d-xl-none" src={'/persons.png'} />
									</Col>
								</Row>
							</Container>
						</Col>
					</Row>
				</Container>
			</Crew>
			<Innovation>
				<Container className="my-0 my-md-5">
					<Row className="d-flex align-items-center justify-content-between my-5">
						<Col xl={5} className="containerAnimation">
							<div className="animation-container" ref={animationContainer} />
						</Col>
						<Col xl={6}>
							<TitleAccessDevice className="text-center text-md-left">Conviértete en parte de<br /> la innovación</TitleAccessDevice>
							<p className="mt-3 text-center text-md-left" css={css`font-size:1.5rem;`}>
								Chile es potencia mundial en temas energéticos por la alta oferta de Energía renovable que nos ofrece nuestro País. Únete al cambio hoy y seamos todos los que salvemos al Planeta.
							</p>
							{/* <div className="text-center text-md-left">
								<Button variant="primary" className="border-radius-20 mt-4">CONOCER MAS</Button>
							</div> */}
						</Col>
					</Row>
				</Container>
			</Innovation>
		</Layout >
	)
}


export default IndexPage
